
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { CceSubCategorieObject } from '@/store/modules/CCE/SubCategoriesModule';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  const submitSubCategoryOptionstButton = ref<HTMLButtonElement | null>(null);

  export default defineComponent({
    name: 'cce-sub-categories-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const createdUser = ref<CceSubCategorieObject | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('cceSubCategoriesUpdating'), [
          translate('cce'),
        ]);
      });
      await store.dispatch(Actions.GET_ALL_CCE_CATEGORIES);
      await store.dispatch(Actions.GET_ALL_CCE_QUALITY);

      const { data } = await store.dispatch(
        Actions.GET_CCE_SUB_CATEGORIE,
        route.params.id
      );

      const cceSubCategorie = ref<CceSubCategorieObject>(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const cceSubCategorieSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_CCE_SUB_CATEGORIE, {
          id: cceSubCategorie.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_CCE_SUB_CATEGORIE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
      };

      let subOptions = data.data.subCategoriesOptions.map((p) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

        return {
          id: p.id,
          qualityId: p.qualityId,
          title: p.title,
          price: p.price,
        };
      });

      if (data.data.subCategoriesOptions.length == 0) {
        subOptions = store.getters.allCceQualityList.map((p) => ({
          id: 0,
          qualityId: p.id,
          title: p.title,
          price: null,
        }));
      }
      const onSubmitSubCategoryOptions = async () => {
        const subCategoriesOptions = subOptions.reduce(
          (previousValue, currentValue) => {
            previousValue.push({
              id: currentValue.id,
              qualityId: currentValue.qualityId,
              title: currentValue.title,
              price:
                currentValue.price != undefined ? currentValue.price : null,
            });
            return previousValue;
          },
          []
        );

        const payload = {
          subCategoryId: cceSubCategorie.value?.id,
          subCategoriesOptions,
        };

        if (submitSubCategoryOptionstButton.value) {
          // eslint-disable-next-line
          submitSubCategoryOptionstButton.value!.disabled = true;
          submitSubCategoryOptionstButton.value.setAttribute(
            'data-kt-indicator',
            'on'
          );
        }

        await store.dispatch(Actions.UPDATE_CCE_SUB_CATEGORIE_OPTIONS, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitSubCategoryOptionstButton.value?.removeAttribute(
            'data-kt-indicator'
          );
          // eslint-disable-next-line
          submitSubCategoryOptionstButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATE_SUB_CATEGORIE_OPTIONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitSubCategoryOptionstButton.value?.removeAttribute(
              'data-kt-indicator'
            );
            // eslint-disable-next-line
            submitSubCategoryOptionstButton.value!.disabled = false;
            router.push({ name: 'cce-sub-categories-listing' });
          });
        }
      };
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        cceQualitys: computed(() => store.getters.allCceQualityList),
        cceCategories: computed(() => store.getters.allCceCategoriesList),
        onSubmitUpdate,
        submitButton,
        submitSubCategoryOptionstButton,
        translate,
        cceSubCategorieSchema,
        goBack,
        cceSubCategorie,
        can,
        createdUser,
        subOptions,
        onSubmitSubCategoryOptions,
      };
    },
  });
